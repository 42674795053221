function ErrorPage() {
    return (
        <div className="error-page text-center p-5">
            <h1>We are off the path!</h1>
            <a className="italic-text" href="/"><p>Click here to return to home page.</p></a>
        </div>
    );
}

export default ErrorPage
