import HomeCards from './HomeCards';
import HomeHero from './HomeHero';
import HomeAboutUs from './HomeAboutUs'
import HomeFaq from './HomeFaq'
// import MaintenanceScreen from './MaintenanceScreen';
import { Helmet } from 'react-helmet';
import FullLogo from '../assets/logos/HeraLogo_MainWhite.96.png'

function Home() {

    return (
        <div >
            <Helmet>
                <title>Hera Pathways | Embrace Mental Wellness</title>
                <meta name="description" content="Your mental health journey begins here. Schedule an appointment with our team today and embark on a path to wellness that honors both your unique challenges and your inherent strength." />
                <meta property="og:title" content="Hera Pathways | Embrace Mental Wellness" />
                <meta property="og:description" content="Your mental health journey begins here. Schedule an appointment with our team today and embark on a path to wellness that honors both your unique challenges and your inherent strength." />
                <meta property="og:image" content={FullLogo} />
                <meta property="og:url" content="/" /> 
            </Helmet>
            <HomeHero />
            <div className="btf-bg">
                <div style={{ padding: '12% 0 0' }}>
                    <HomeCards />
                    <HomeAboutUs />
                </div>

            </div>
            <div className="five-arrow-bg">
                <HomeFaq />
            </div>
            {/* <MaintenanceScreen /> */}
        </div>
    )
}

export default Home;
