import Accordion from 'react-bootstrap/Accordion';
// import { NavLink } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const HomeAccordion = () => {
    return (
        <div className="mini-faqs container">
            {/* <h2 className="text-center pb-5" style={{ fontSize: '2rem', color: '#14555e' }}>Questions?<bt />Here are some of the more common questions we recieve.</h2> */}
            <Accordion style={{ width: '85%', margin: 'auto' }} defaultActiveKey={['0']} alwaysOpen>
                <h2 className="text-center" style={{ fontSize: '2rem', color: '#14555e' }}>Questions?<br />Here are some of the more common questions we recieve.</h2>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Do you serve areas outside the Seattle area?
                    </Accordion.Header>
                    <Accordion.Body>
                        Yes we serve areas outside of Seattle in the state of Washington.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>What is a Good Faith Estimate?</Accordion.Header>
                    <Accordion.Body>
                        A Good Faith estimate is an estimate provided which outlines the expected costs or expenses that a customer or client might incur. The Consolidated Appropriations Act of 2021 was enacted on December 27, 2020, and contains many provisions to help protect consumers from surprise medical bills under what's known as the No Surprises Act. The protections took effect on January 1, 2022, and apply to all healthcare providers in the U.S.<br /><br />

                        You have the right to receive a “Good Faith Estimate” explaining how much your medical and mental health care will cost.<br /><br />

                        Under the law, healthcare providers need to give patients who don’t have insurance, or who are not using insurance, an estimate of the expected charges for medical services, which includes mental health care.

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Are sessions currently online (telehealth) or in-person?</Accordion.Header>
                    <Accordion.Body>
                        All sessions are currently held online. We use a HIPAA- compliant platform for scheduling, billing and telehealth sessions.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>What about rates and insurance?
                    </Accordion.Header>
                    <Accordion.Body>
                        We are currently not paneled with insurance and working towards that process. The fee for an initial psychotherapy appointment that is 60 minutes in length is $250.00 Follow up appointments fees are based on time for a 60 minute follow up appointment the fee is $175.00 and a 45 minute follow up appointment is $150.00. We can provide a receipt that you can then submit to your insurance for reimbursement.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>How long does a course of therapy typically last?</Accordion.Header>
                    <Accordion.Body>
                        The course of therapy varies. It can be anywhere from a few sessions to several months or even years depending on the goals, progress and needs of the individual.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>Can I use my Health Savings Account (HSA)?
                    </Accordion.Header>
                    <Accordion.Body>
                        Yes. You can use an HSA to cover the cost of some mental health expenses or to get reimbursed. You can always verify this with your HSA administrator.
                    </Accordion.Body>
                </Accordion.Item>
                <Link to="/faqs">
                    <p className='ml-5' >
                        Click here for more frequently asked questions.
                    </p>
                </Link>
            </Accordion>

        </div>
    );
}

export default HomeAccordion;