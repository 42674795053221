import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const location = useLocation();

  if (location.pathname !== '/') {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }

  return null;
}

export default ScrollToTop