import Card from 'react-bootstrap/Card';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'
import Calendar from '../assets/images/MaskGroup2.png'
import Device from '../assets/images/MaskGroup3.png'
import Sprouts from '../assets/images/MaskGroup4.png'
import { Fade } from "react-swift-reveal";



function HomeCards() {
    return (
        <>
            <Container style={{ display: 'grid' }}>
                <div className="big-btn mt-3 mb-5" style={{ placeSelf: 'center' }}>Therapy on Your Terms</div>
            </Container>
            <Container>
                <Row style={{ padding: '10% 0' }}>
                    <div className="col-lg-4 col-md-12 card-roles pt-5">
                        <Fade
                        delay={500} duration={700}>
                            <Card style={{ maxWidth: '23rem', background: '#14555e', boxShadow: '0 2px 6px #95989A' }} className="p-3 text-center">
                                <Card.Img variant="top" src={Calendar} className="card-img-size pt-3" />
                                <Card.Body>
                                    <Card.Title className="bold-text pb-3" style={{ color: '#ffffff' }}>Schedule Your Appointment</Card.Title>
                                    <Card.Text style={{ color: '#ffffff', fontSize: "20px" }}>
                                        You're in the driver's seat—schedule sessions that suit your life. Experience a contemporary take on therapy, where virtual sessions blend tradition with innovation. It's mental wellness, your way.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Fade>
                    </div>
                    <div className="col-lg-4 col-md-12 pt-5 card-roles">
                        <Fade delay={1000} duration={700}>
                            <Card style={{ maxWidth: '23rem', background: '#ffffff', color: '#14555e', boxShadow: '0 2px 6px #95989A' }} className="p-3 text-center">
                                <Card.Img variant="top" src={Device} className="card-img-size pt-3" />
                                <Card.Body>
                                    <Card.Title className="bold-text pb-3" style={{ color: '#14555e' }}>Your Healing Space</Card.Title>
                                    <Card.Text style={{ color: '#14555e', fontSize: "20px" }}>
                                        During our intake session, we'll have an open conversation about what brings you here, your journey so far, and the positive steps you're eager to take. This is your chance to ask any questions you might have about how we can work together to support your well-being.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Fade>
                    </div>
                    <div className="col-lg-4 col-md-12 pt-5 card-roles">
                        <Fade delay={1500} duration={700}>
                            <Card style={{ maxWidth: '23rem', background: '#F6DC99', boxShadow: '0 2px 6px #95989A' }} className="p-3 text-center">
                                <Card.Img variant="top" src={Sprouts} className="card-img-size pt-3" />
                                <Card.Body>
                                    <Card.Title className="bold-text pb-3" style={{ color: '#14555e' }}>Embrace Renewal</Card.Title>
                                    <Card.Text style={{ color: '#14555e', fontSize: "20px" }}>
                                        Through empathetic counseling and personalized support, we'll guide you toward a renewed sense of well-being. Whether you're facing the challenges of pregnancy, postpartum, fertility issues, or any emotional hurdles, our goal is to help you find relief and embrace a brighter tomorrow.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Fade>
                    </div>
                </Row>
            </Container >
        </>
    );
}

export default HomeCards;