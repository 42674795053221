import { useEffect } from 'react';
import ReactGA from 'react-ga4';

const Analytics = ({ trackingId }) => {
  useEffect(() => {
    ReactGA.initialize(trackingId);
    // Additional configuration can go here
    // For example, to record page views:
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, [trackingId]);

  return null; // This component does not render anything
};

export default Analytics;
