import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import FullLogo from '../assets/logos/HeraLogo_MainWhite.96.png'

function FAQs() {
    return (
        <>
            <Helmet>
                <title>Frequently Asked Questions | Hera Pathways</title>
                <meta name="description" content="We want to provide you with all the information you need to make an informed decision." />
                <meta property="og:title" content="Frequently Asked Questions" />
                <meta property="og:description" content="We want to provide you with all the information you need to make an informed decision." />
                <meta property="og:image" content={FullLogo} />
                <meta property="og:url" content="/faqs" />
            </Helmet>
            <div className='faq-bg'>
                <h1 className="prime-cta text-center">Frequently Asked Questions</h1>
            </div>
            <div className="mini-faqs container" style={{ marginBottom: '-30%', paddingTop: '5%' }}>
                <p className="pt-2 pb-5" style={{ fontSize: 'calc(5px + 3vmin)', color: '#14555e' }}>We want to make sure that you have all the information you need to make an informed decision about our services.</p>
                <Accordion style={{ width: '85%', margin: 'auto' }} defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Do you serve areas outside the Seattle area?
                        </Accordion.Header>
                        <Accordion.Body>
                            Yes we serve areas outside of Seattle in the state of Washington.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>What is a Good Faith Estimate?</Accordion.Header>
                        <Accordion.Body>
                            A Good Faith estimate is an estimate provided which outlines the expected costs or expenses that a customer or client might incur. The Consolidated Appropriations Act of 2021 was enacted on December 27, 2020, and contains many provisions to help protect consumers from surprise medical bills under what's known as the No Surprises Act. The protections took effect on January 1, 2022, and apply to all healthcare providers in the U.S.<br /><br />

                            You have the right to receive a “Good Faith Estimate” explaining how much your medical and mental health care will cost.<br /><br />

                            Under the law, healthcare providers need to give patients who don’t have insurance, or who are not using insurance, an estimate of the expected charges for medical services, which includes mental health care.

                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Are sessions currently online (telehealth) or in-person?</Accordion.Header>
                        <Accordion.Body>
                            All sessions are currently held online. We use a HIPAA- compliant platform for scheduling, billing and telehealth sessions.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>What about rates and insurance?
                        </Accordion.Header>
                        <Accordion.Body>
                            We are currently not paneled with insurance and working towards that process. The fee for an initial psychotherapy appointment that is 60 minutes in length is $250.00 Follow up appointments fees are based on time for a 60 minute follow up appointment the fee is $175.00 and a 45 minute follow up appointment is $150.00. We can provide a receipt that you can then submit to your insurance for reimbursement.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>How long does a course of therapy typically last?</Accordion.Header>
                        <Accordion.Body>
                            The course of therapy varies. It can be anywhere from a few sessions to several months or even years depending on the goals, progress and needs of the individual.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header>Can I use my Health Savings Account (HSA)?
                        </Accordion.Header>
                        <Accordion.Body>
                            Yes. You can use an HSA to cover the cost of some mental health expenses or to get reimbursed. You can always verify this with your HSA administrator.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header>Where are you located?
                        </Accordion.Header>
                        <Accordion.Body>
                            We are located in Seattle, WA however since we do teletherapy appointments we can see anyone in the state of Washington.

                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                        <Accordion.Header>Why do you request a credit card when we first sign up?
                        </Accordion.Header>
                        <Accordion.Body>
                            Having your credit card on file streamlines the payment process, making it more convenient for you. This ensures that you can focus on your mental health journey without worrying about any administrative hassles. Providing your credit card information helps us in securing your appointment and ensures that you are committed to the scheduled time. Having your credit card information allows us to collect any fees or charges associated with your appointment including copayments or services not covered by insurance.  We will only charge after the session has been completed or if you miss the appointment without giving proper notice. Please be assured that your privacy and confidentiality are paramount to us. We adhere to strict security measures to protect your personal and financial information. All the information is stored in our HIPAA-compliant secure system.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                        <Accordion.Header>How do I schedule an appointment?
                        </Accordion.Header>
                        <Accordion.Body>
                            You can schedule an appointment by clicking the book appointment link in the website.
                            Also linked <a href="https://api.portal.therapyappointment.com/n/public/clientRegistration?therapistId=9A4EE40115224FBF8A651F8CB78A4981" rel="noreferrer" className="italic-text" title="Click to schedule an appointment" target="_blank">here.</a>

                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9">
                        <Accordion.Header>How long are sessions?
                        </Accordion.Header>
                        <Accordion.Body>
                        Typically sessions vary in length from 45 minutes to 60 minutes. 

                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="10">
                        <Accordion.Header>Do you see couples? 
                        </Accordion.Header>
                        <Accordion.Body>
                        Although we currently do not offer couple’s therapy we can discuss bringing in a partner for additional support in your wellness goals.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="11">
                        <Accordion.Header>Do you only see those going through pregnancy?

                        </Accordion.Header>
                        <Accordion.Body>
                        While we do serve “women” going through any part of their pregnancy journey. We serve any individual seeking support for any mental health concerns. 
                        </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey="12">
                        <Accordion.Header>How long are sessions?
                        </Accordion.Header>
                        <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                            aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                            culpa qui officia deserunt mollit anim id est laborum.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="13">
                        <Accordion.Header>Do you see couples?
                        </Accordion.Header>
                        <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                            aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                            culpa qui officia deserunt mollit anim id est laborum.
                        </Accordion.Body>
                    </Accordion.Item> */}
                </Accordion>
                <p className="p-5 text-center" style={{ fontSize: 'calc(5px + 3vmin)', color: '#14555e' }}>If you have a question that isn't answered here, please don't hesitate to contact us.</p>
                <div className="text-center">
                    <Button style={{ padding: '2%' }} variant="secondary" size='lg' href="mailto:admin@herapathways.com?subject=Hera Pathways FAQs page | I have another question." target='_blank'>Send us an Email</Button>
                </div>
            </div>
            <div className="leaves-2"></div>
        </>
    );
}

export default FAQs;
