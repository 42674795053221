// import Cathy85k from '../assets/logos/Hera Logo_Main Deep Teal.png';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Fade, Slide } from "react-swift-reveal";


const HomeAboutUs = () => {

    return (
        <section style={{ padding: '14% 0 0' }}>
            {/* <Container fluid className="about-bg" style={{ background: 'linear-gradient(180deg, #e3a405 0%, #eaa703 100%)'}}> */}
            <Container fluid className="about-bg">
                <Container className="pt-5 pb-5">
                    <Fade big delay={500} timeout={1000} duration={1000}>
                        <h1 style={{ textAlign: 'center', fontSize: 'calc(45px + 3vmin)', color: '#14555e' }}>About Hera</h1>
                    </Fade>
                    <Row className="pt-2 pb-5">
                        <Col sm={12} md={12}>
                            <Slide
                                left
                                big
                                delay={500} timeout={1000} duration={1000}>
                                <p className='profile-copy'>
                                    Hera, the goddess often revered as a symbol of love, fidelity, and family, resonates profoundly with our mission. In Greek mythology, Hera is celebrated as the goddess of women, marriage, family, and childbirth. She embodies strength, resilience, and a fierce protectiveness over her loved ones. Despite the complexities and contradictions of her character, Hera's unwavering dedication to her family is a guiding light for our approach.
                                </p>
                            </Slide>
                        </Col>
                        <Col sm={12} md={6}>
                        <Slide
                                left
                                big
                                delay={500} timeout={1000} duration={1000}>
                            <h3 className="lcsw">Compassion, Empowerment, and Collaboration</h3>
                            <p className='profile-copy'>
                                <ul style={{ textDecoration: "none" }}>
                                    <li><em>Compassion: </em>Understanding and empathizing with your journey.</li>
                                    <li><em>Empowerment: </em>Encouraging you to embrace your strength and identity.</li>
                                    <li><em>Collaboration: </em>Working together to find the best paths forward.</li>
                                </ul>
                            </p>
                            </Slide>
                        </Col>
                        <Col sm={12} md={6}>
                        <Slide
                                right
                                big
                                delay={500} timeout={1000} duration={1000}>
                            <h3 className="lcsw">Our Services Reflect Hera's Legacy</h3>
                            <p className="profile-copy">
                                <ul style={{ textDecoration: "none" }}>
                                    <li><em>Family Dynamics and Relationships: </em>Just as Hera protected marriage and family, we offer a safe space to explore family dynamics, relationships, and their impact on your mental health.</li>
                                    <li><em>Empowerment and Identity: </em>Inspired by Hera's strength, we focus on building self-esteem, setting boundaries, and finding your voice in relationships.</li>
                                    <li><em>Resilience and Endurance: </em>Reflecting Hera's endurance, we encourage discussions about resilience, coping with adversity, and finding strength in difficult times.</li>
                                </ul>
                            </p>
                            </Slide>
                        </Col>
                        <Col sm={12} md={12}>
                        <Slide
                                bottom
                                big
                                delay={500} timeout={1000} duration={1000}>
                            <h3 className='lcsw'>Join Us on Your Path to Wellness</h3>
                            <p className='profile-copy'>
                                At Hera Pathways, we are dedicated to guiding you through your mental health journey with the same strength, resilience, and compassion as our namesake. Schedule an appointment with our team today and embark on a path to wellness that honors both your unique challenges and your inherent strength.
                            </p>
                            </Slide>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </section >
    )
}

export default HomeAboutUs