import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Helmet } from 'react-helmet';
import FullLogo from '../assets/logos/HeraLogo_MainWhite.96.png'


function Resources() {

    return (
        <div>
            <Helmet>
                <title>Resources | Hera Pathways</title>
                <meta name="description" content="For additional services and information please see the links below." />
                <meta property="og:title" content="Resources" />
                <meta property="og:description" content="For additional services and information please see the links below." />
                <meta property="og:image" content={FullLogo} />
                <meta property="og:url" content="/resources" />
            </Helmet>
            <Container>
                <Row>
                    <Col>
                        <div className="resource-links">
                            <h2>Perinatal and Pregnancy</h2>
                            <ul style={{ textDecoration: 'none' }}>
                                <li>
                                    <a href="https://www.postpartum.net/" rel="noreferrer" target="_blank" title="Postpartum.net">Postpartum Support International</a>
                                </li>
                                <li>
                                    <a href="https://perinatalsupport.org/" rel="noreferrer" target="_blank" title="Perinatal Support">Perinatal Support Washington</a>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col>
                        <div className="resource-links">
                            <h2>Crisis & Suicide</h2>
                            <ul style={{ textDecoration: 'none' }}>
                                <li>
                                    <a href="https://988lifeline.org/" rel="noreferrer" target="_blank" title="988 Suicide & Crisis Lifeline">988 Suicide & Crisis Lifeline</a>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );

}

export default Resources;
