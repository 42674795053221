import Container from 'react-bootstrap/Container'
import WithXavi from '../assets/images/withXavi.jpg'
import family from '../assets/images/family.152.jpg'
import Mom_Baby from '../assets/images/mom-and-baby.jpg'
import Profile from '../assets/images/CathyProfile.jpg'
// import bushes_leaves from '../assets/images/bushes-of-leaves-svgrepo-com.png'
import Support from '../assets/images/CathyManny.jpg'
import { Helmet } from 'react-helmet'
// import FullLogo from '../assets/logos/HeraLogo_MainWhite.96.png'
// import MetaPhoto from '../assets/logos/HeraLogo_MainFullColor.png'

function About() {
    
    return (
        <>
            <Helmet>
                <title>About | Hera Pathways</title>
                <meta name="description" content="Our compassionate therapists specialize in supporting you as you navigate the emotional ups and downs of this transformative journey." />
                <meta property="og:title" content="About Us" />
                <meta property="og:description" content="Our compassionate therapists specialize in supporting you as you navigate the emotional ups and downs of this transformative journey." />
                <meta property="og:image" content="https://www.herapathways.com/static/media/Hera%20Logo_Main%20Full%20Color_sansTitle.4809f62b843a566ba4ed.png" />
                <meta property="og:url" content="https://herapathways.com/about" />
            </Helmet>
            <Container className='about-design'>
                <div className='about-copy'>
                    <h2 className="bold-text">Cathlyn Fraguela Rios<br />LICSW</h2>
                    <h3 className="bold-font">Hera Pathways Founder</h3>
                    <p>
                        <em>Education: </em> Masters of Social Work University of Washington.<br /><br />
                        <em>Practice Philosophy: </em>I believe that making connections with people is the cornerstone of doing meaningful work with them. Helping them think through whatever challenges they are going through. I envision my work to be figuring out what works for each individual so that the change can be sustainable.<br /><br /><em>Experience: </em>My experience includes working in community mental health, Inpatient psychiatry, integrated primary care and private practice. I have training in different evidence based practices such as Motivational Interviewing, Cognitive Behavioral Therapy (CBT), Trauma Focused Cognitive Behavioral Therapy (TF-CBT), Acceptance and Committement Therapy (ACT), Interpersonal Psychotherapy as well as Mindfulness stress reduction. Each offer valuable strategies and techniques that I bring to sessions and offer what might work best for you. I have worked with individuals at different ages and stages in their lives from children in school based therapy to older adults with Alzeihmers and their families. My approach is to truly understand and help forge a path forward supporting you along the way.<br /><br /><em>Additional Languages Spoken: </em>Spanish<br /><br /><em>Licensed and providing services in: </em> AK, CA, ID, MT, NM, OR, TX, and WA
                    </p>
                </div>
                <div className="about-img-1">
                    <img src={Profile} alt='Professional headshot of Cathlyn.'></img>
                </div>
                <div className="about-img-2">
                    <img src={family} alt="Cathly with her husband and son."></img>
                </div>
                <div className="about-img-3">
                    <img src={Mom_Baby} alt="Cathlyn and her new born baby sitting with sunshine coming in through the window."></img>
                </div>
                <div className="about-img-4">
                    <img src={WithXavi} alt="Cathlyn and her son"></img>

                </div>
                {/* <div className="about-img-5">
                <img src={Baby_hand}></img>
            </div>
            <div className="about-img-6">
                <img src={Baby_and_Mom}></img>
            </div> */}
                <div className="about-img-7">
                    <img src={Support} alt="Cathlyn and her dog on a walk with mountains and water in the background"></img>
                </div>
            </Container>
            <div className="bush_leaves"></div>
        </>
    );
}

export default About;