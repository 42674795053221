import React, { useState } from 'react'
import "./NavBar.css"
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { Link } from "react-router-dom"
import logo from './assets/logos/Hera Logo_Alt Full Color_555.png'


function NavBar() {

    const [expanded, setExpanded] = useState(false);

    return (
        <Navbar expanded={expanded} expand="lg" className="bg-body-tertiary">
            <Container>
                <Navbar.Brand href="/">
                    <img
                    src={logo}
                    alt="Hera Pathways Main Logo in White"         
                    className="logo d-inline-block align-top"
                    />{' '}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="justify-content-end me-auto">
                        <Link
                            to="/"
                            className="nav-link nav-style navbar-nav"
                            onClick={() => setExpanded(false)}
                        >HOME
                        </Link>
                        <Link
                            to="/about"
                            className="nav-link nav-style navbar-nav"
                            onClick={() => setExpanded(false)}
                        >ABOUT
                        </Link>
                        <Link
                            to="/faqs"
                            className="nav-link nav-style navbar-nav"
                            onClick={() => setExpanded(false)}
                        >FAQS
                        </Link>
                        <Link
                            to="/services"
                            className="nav-link nav-style navbar-nav"
                            onClick={() => setExpanded(false)}
                        >SERVICES
                        </Link>
                        <Link
                            to="/resources"
                            className="nav-link nav-style navbar-nav"
                            onClick={() => setExpanded(false)}
                        >RESOURCES
                        </Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavBar
